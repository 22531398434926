body {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f5f8;
  /* for demo only */
  margin: 8px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  /* Colors */
  --vector-blue: #0271ce;
  --deepSquidInk: #152939;
  --grey: #828282;
  --lightGrey: #c4c4c4;
  --silver: #e1e4ea;
  --white: #ffffff;

  /* Theme */
  --primary-color: var(--vector-blue);
  --button-color: var(--white);
  --button-background-color: var(--primary-color);
  --button-click: var(--vector-blue);
  --link-color: var(--primary-color);
  --form-color: var(--white);
  --input-color: var(--deepSquidInk);
  --input-background-color: var(--white);
}

p,
span {
  color: var(--deepSquidInk);
}

/* Section */
.container {
  font-weight: 400;
}

.actionRow {
  margin-bottom: 15px;
}

.sectionHeader {
  color: var(--deepSquidInk);
  margin-bottom: 34px;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}

.sectionHeaderHint {
  color: var(--grey);
  font-size: 16px;
  font-weight: 400;
  margin-top: 4px;
}

.sectionBody {
  margin-bottom: 30px;
}

.sectionButtons {
  display: grid !important;
  text-align: center !important;
}

.sectionButtons > * {
  text-transform: none !important;
  font-size: 14px !important;
  font-stretch: 100% !important;
  font-weight: 700 !important;
}

.sectionButtons > *:not(:last-child) {
  margin-bottom: 15px !important;
}

.sectionFooter {
  font-size: 14px;
  color: var(--grey);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sectionFooterPrimaryContent {
  text-align: center;
}

.sectionFooterSecondaryContent {
  align-self: center;
}

@media only screen and (max-width: 599px) {
  .sectionFooter {
    flex-wrap: wrap;
  }

  .sectionFooterPrimaryContent {
    width: 100%;
    margin-bottom: 32px;
  }

  .sectionFooterPrimaryContent > button {
    width: 100%;
  }

  .sectionFooterSecondaryContent {
    text-align: center;
    flex: 0 0 100%;
  }
}

/* Form */

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 5% auto 50px;
}

.formContainer > .brandLogo {
  width: 80%;
  max-width: 360px;
  margin-bottom: 30px;
}

.formSection {
  position: relative;
  margin-bottom: 20px;
  background-color: var(--form-color);
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  min-width: 460px;
  max-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}

.formField {
  margin-bottom: 15px;
}

.formRow {
  margin-bottom: 12px;
}

@media only screen and (max-width: 599px) {
  .formContainer {
    margin-top: 30px;
  }

  .formSection {
    width: 100%;
    box-sizing: border-box;
    padding: 35px 25px;
    box-shadow: none;
    border-radius: 0;
    min-width: auto;
  }
}

/* Input */

.input-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.input-addornment,
.input {
  min-height: 53px;
  padding: 16px;
  font-size: 14px;
  color: var(--input-color);
  background-color: var(--input-background-color);
  background-image: none;
  border: 1px solid var(--lightGrey);
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.input {
  width: 100%;
  flex-grow: 1;
}

.input-addornment {
  flex-grow: 0;
  color: var(--grey);
}

.input-addornment.input-addornment-start {
  padding-right: 4px;
  border-right-width: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.input.start-addorned {
  padding-left: 0px;
  border-left-width: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.input-footer {
  display: flex;
  justify-content: space-between;
}

.input:focus {
  outline: none;
}

.disabled .input-addornment,
.disabled .input,
.input:disabled {
  color: #545454;
  background-color: var(--silver);
}

.inputLabel {
  color: var(--input-color);
  font-size: 14px;
  margin-bottom: 8px;
}

.label {
  color: var(--input-color);
}

.radio {
  margin-right: 18px;
  vertical-align: bottom;
}

/* Checkbox */

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

label {
  position: relative;
  display: inline-block;
  padding: 0 0 0 2em;
  height: 1em;
  line-height: 1.8;
  cursor: pointer;
}

label::before,
label::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 1.5em;
  height: 1.5em;
}

label::before {
  content: " ";
  border: 2px solid var(--primary-color);
  border-radius: 20%;
  height: 18px;
  width: 18px;
}

input[type="checkbox"] + label::after {
  vertical-align: middle;
  content: "\2714";
  font-size: 16px;
  color: #fff;
  line-height: 1.5;
  text-align: center;
}

input[type="checkbox"]:checked + label::before {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

input[type="checkbox"] + label::after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

input[type="checkbox"]:checked + label::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* Transition */
label::before,
label::after {
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}

/* Validation  */

.error-message {
  color: #cc0033;
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
}

.input-group .error-message {
  display: none;
}

.error .inputLabel {
  color: #cc0033;
}

.error .input-addornment,
.error input {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

.error .input-addornment-start {
  border-right-width: 0px;
}

.error .start-addorned {
  border-left-width: 0px;
}

.error .error-message {
  display: inline-block;
}

/* Button */
.button {
  min-width: 153px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  color: var(--button-color);
  background-color: var(--button-background-color);
  border-color: #ccc;
  text-transform: uppercase;
  padding: 14px 0;
  letter-spacing: 1.1px;
  border: none;
}

.button:active {
  opacity: 1;
  background-color: var(--button-click);
}

.button:hover,
.signInButton:hover {
  opacity: 0.8;
}

.button:disabled {
  opacity: 0.4;
  cursor: auto;
}

.signInSsoButton {
  width: 100%;
}

.signInButton {
  position: relative;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0;
  color: var(--deepSquidInk);
  font-size: 14px;
  box-sizing: content-box;
}

.signInButtonIcon {
  position: absolute;
  left: 0;
}

.signInButtonContent {
  text-align: center;
  display: block;
  padding: 18px 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.continueAsButton {
  padding: 14px 5px;
}

/* Anchor */
.a {
  color: var(--link-color) !important;
  cursor: pointer;
  text-decoration: none;
}
.a:hover {
  text-decoration: underline;
}

/* Hint */

.hint {
  color: var(--grey);
  font-size: 12px;
  text-align: right;
}

/* Backdrop */

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  padding: 10px;
  display: inline-block;
}

.loading-spinner.color-primary {
  stroke: var(--primary-color);
}

.panel-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

/* Spinner */

.lds-dual-ring {
  display: block;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 50px;
  height: 50px;
  margin: 8px;
  border-radius: 50%;
  border: 12px solid var(--primary-color);
  border-color: var(--primary-color) transparent var(--primary-color)
    transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: inherit;
  font-weight: bold;
  color: inherit;
  text-rendering: optimizelegibility;
}

h4 {
  font-size: 14px;
}
h4,
h5,
h6 {
  line-height: 18px;
}

p {
  margin: 0 0 9px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 18px;
}

.close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.close:hover {
  color: #000000;
  text-decoration: none;
  opacity: 0.4;
  filter: alpha(opacity=40);
  cursor: pointer;
}

.alert {
  padding: 14px 35px 14px 14px;
  margin-bottom: 18px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #c09853;
  text-align: justify;
}
.alert-heading {
  color: inherit;
}
.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: 18px;
}
.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
}
.alert-danger,
.alert-error {
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48;
}
.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #3a87ad;
}
.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}

/** Tile **/

.tile-list {
  list-style: none;
  padding: 0;
}

.tile {
  text-decoration: none;
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
  cursor: pointer;
}

.tile:hover {
  background-color: rgba(26, 115, 232, 0.15);
}

.tile.disabled:hover {
  background-color: #ccc;
}

@media (min-width: 450px) {
  .tile {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.tile-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid rgb(218, 220, 224);
}

.tile-image {
  border-radius: 50%;
  color: rgb(95, 99, 104);
  overflow: hidden;
  flex: 0 0 auto;
  margin-right: 15px;
}

.tile-image > img {
  border-radius: 50%;
  display: block;
  max-height: 100%;
  max-width: 100%;
}

.tile-text {
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.tile-title {
  line-height: 1.4286;
  color: rgb(60, 64, 67);
  font-size: 14px;
}

.tile-subtitle {
  direction: ltr;
  font-size: 12px;
  text-align: left;
  line-height: 1.3333;
  word-break: break-all;
  color: rgb(95, 99, 104);
}

.sb-avatar--text span {
  font-weight: 600;
}
